@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  /* These vars have been borrowed from dopinghafiza.com so i can work width the same color set */
  --white: rgb(var(--white-value));
  --cultured: rgb(var(--cultured-value));
  --alice-blue: rgb(var(--alice-blue-value));
  --light-gray: rgb(var(--light-gray-value));
  --manatee: rgb(var(--manatee-value));
  --cadet-gray: rgb(var(--cadet-gray-value));
  --roman-silver: rgb(var(--roman-silver-value));
  --gunmetal: rgb(var(--gunmetal-value));
  --charcoal: rgb(var(--charcoal-value));
  --lava: rgb(var(--lava-value));
  --light-sky-blue: rgb(var(--light-sky-blue-value));
  --cornflower-blue: rgb(var(--cornflower-blue-value));
  --galliano: rgb(var(--galliano-value));
  --honolulu-blue: rgb(var(--honolulu-blue-value));
  --blue-munsell: rgb(var(--blue-munsell-value));
  --medium-aquamarine: rgb(var(--medium-aquamarine-value));
  --green-RYB: rgb(var(--green-RYB-value));
  --medium-purple: rgb(var(--medium-purple-value));
  --cyber-grape: rgb(var(--cyber-grape-value));
  --blush: rgb(var(--blush-value));
  --amber: rgb(var(--amber-value));
  --fulvous: rgb(var(--fulvous-value));
  --tan-crayola: rgb(var(--tan-crayola-value));
  --navy-blue: rgb(var(--navy-blue-value));
  --red-salsa: rgb(var(--red-salsa-value));
  --dark-electric-blue: rgb(var(--dark-electric-blue-value));
  --cheat-gray: rgb(var(--cheat-gray-value));
  --price-success: rgb(var(--price-success-value));

  --white-value: 255,255,255;
  --cultured-value: 250,250,252;
  --alice-blue-value: 241,246,249;
  --light-gray-value: 213,215,217;
  --manatee-value: 148,155,165;
  --cadet-gray-value: 152,168,180;
  --roman-silver-value: 127,133,141;
  --gunmetal-value: 29,37,45;
  --charcoal-value: 58,71,89;
  --lava-value: 204,23,27;
  --light-sky-blue-value: 160,214,255;
  --cornflower-blue-value: 107,147,214;
  --galliano-value: 217,173,41;
  --honolulu-blue-value: 11,106,178;
  --blue-munsell-value: 44,149,172;
  --medium-aquamarine-value: 109,217,159;
  --green-RYB-value: 113,181,27;
  --medium-purple-value: 148,107,214;
  --cyber-grape-value: 76,53,114;
  --blush-value: 214,107,133;
  --amber-value: 255,191,0;
  --fulvous-value: 224,124,16;
  --tan-crayola-value: 217,161,109;
  --navy-blue-value: 12,0,88;
  --red-salsa-value: 255,55,70;
  --dark-electric-blue-value: 94,107,121;
  --cheat-gray-value: 252,252,252;
  --price-success-value: 62,182,87;

  --default-box-shadow: 0px 0px 10px 0px rgba(var(--charcoal-value), 0.05);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: var(--charcoal);
  background: var(--white);
  /* background: var(--cultured); */
}

.container {
  @apply w-full mx-auto px-4 py-2 sm:w-[600px] md:w-[720px] lg:w-[960px] xl:w-[1200px];
}

.container-full {
  @apply w-full mx-auto px-4 py-2;
}

@layer utilities {
  .custom-scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .custom-scroll::-webkit-scrollbar-track {
    background: #fcfcfc;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 100vh;
  }

  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #e4e4e4;
  }
}

.btn {
  display: inline-block;
  position: relative;
  padding: 0 32px;
  height: 55px;
  border: 1px solid var(--honolulu-blue);
  color: var(--honolulu-blue);
  background-color: var(--white);
  opacity: 1;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  transition: all .2s;

  &:hover {
    opacity: .9;
  }

  &.btn-blue {
    background-color: var(--honolulu-blue);
    color: var(--white);
    &:hover {
      opacity: .9;
    }
  }

  &.btn-red {
    border-color: var(--lava);
    background-color: var(--lava);
    color: var(--white);
    &:hover {
      opacity: .9;
    }
  }

  &.btn-trans-white {
    border-color: var(--white);
    background-color: transparent;
    color: var(--white);
    &:hover {
      background-color: var(--white);
      color: var(--charcoal);
    }
  }

  &.btn-trans-red {
    border-color: var(--white);
    background-color: transparent;
    color: var(--white);
    &:hover {
      border-color: var(--lava);
      background-color: var(--lava);
    }
  }

  &.std-btn {
    &:hover {
      background-color: var(--honolulu-blue);
      color: var(--white);
    }
  }
}